<template>
  <div class="main_container">
    <!-- Ligne n°1 -->
    <!-- Fin ligne n°1 -->
    <!-- Ligne n°2 -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  filename="detail_peage"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <!-- <section slot="pdf-content"> -->
      
      <div slot="pdf-content"
           class="row my-1"
           style="padding-left:50px">
        
        <table class="table table-blue">
          <thead>
            <tr>
              <th class="th-blue text-center">GARES</th>
              <th class="th-blue text-center">RECETTES</th>
            </tr>
          </thead>
          <tbody v-if="yearData != null">
            <tr v-for="(recette, ykey) in yearData.tableau"
                :key="ykey">
              <td scope="row">{{ recette.gare }}</td>
              <td class="text-center">{{ Math.round(Number(recette.recettes)).toLocaleString() }} FCFA</td>
            </tr>
            <tr class="tht-blue d-content-dark-blue text-center">
              <td scope="row">TOTAUX</td>
              <td class="text-center">{{ Math.round(Number(total)).toLocaleString() }} FCFA</td>
            </tr>
          </tbody>
          <tbody v-else>
            <td colspan="2"
                class="text-center">Aucune donnée disponible...</td>
          </tbody>
        </table>

        <table class="table table-striped table-blue">
          <thead>
            <tr>
              <th class="text-center"></th>
              <th colspan="7"
                  class="text-center tht-blue">{{ gare }}</th>
            </tr>
          </thead>
          <tbody v-if="gareData != null">
            <tr class="text-center">
              <td >Mois</td>
              <td>classe 1</td>
              <td>classe 2</td>
              <td>classe 3</td>
              <td>classe 4</td>
              <td>classe 5</td>
              <td class="tht-blue">SOUS-TOTAL</td>
            </tr>
  
            <tr v-for="(mois, mkey) in month"
                :key="mkey">
              <td >{{mois.mois}}</td>
              <td class="text-right">{{Math.round(Number(gareData.classe_1[mkey])).toLocaleString()}}</td>
              <td class="text-right">{{Math.round(Number(gareData.classe_2[mkey])).toLocaleString()}}</td>
              <td class="text-right">{{Math.round(Number(gareData.classe_3[mkey])).toLocaleString()}}</td>
              <td class="text-right">{{Math.round(Number(gareData.classe_4[mkey])).toLocaleString()}}</td>
              <td class="text-right">{{Math.round(Number(gareData.classe_5[mkey])).toLocaleString()}}</td>
              <td class="text-right tht-blue">{{Math.round(Number(mois.total)).toLocaleString()}}</td>
            </tr>
            <tr class="tr-blue">
              <td >TOTAUX</td>
              <td class="text-right">{{ Math.round(Number(gareData.totaux.classe_1)).toLocaleString() }}</td>
              <td class="text-right">{{ Math.round(Number(gareData.totaux.classe_2)).toLocaleString() }}</td>
              <td class="text-right">{{ Math.round(Number(gareData.totaux.classe_3)).toLocaleString() }}</td>
              <td class="text-right">{{ Math.round(Number(gareData.totaux.classe_4)).toLocaleString() }}</td>
              <td class="text-right">{{ Math.round(Number(gareData.totaux.classe_5)).toLocaleString() }}</td>
              <td class="tht-blue text-right">{{Math.round(Number(monthTotal)).toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div style="width: 700px; margin: auto">
            <BarChart :chartdata="randomChart1"
                      :options="optionsChart1" />
          </div>
        </div>
      </div>
      <!-- </section> -->
    </vue-html2pdf>
    <!-- END of Vue-html2pdf  -->

    <div class="row mt-4">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-7">
        <table class="table table-blue">
          <thead>
            <tr>
              <th class="th-blue text-center">GARES</th>
              <th class="th-blue text-center">RECETTES</th>
            </tr>
          </thead>
          <tbody v-if="yearData != null">
            <tr v-for="(recette, ykey) in yearData.tableau"
                :key="ykey">
              <td scope="row">{{ recette.gare }}</td>
              <td class="text-center">{{ Math.round(Number(recette.recettes)).toLocaleString() }} FCFA</td>
            </tr>
            <tr class="tht-blue d-content-dark-blue text-center">
              <td scope="row">TOTAUX</td>
              <td class="text-center">{{ Math.round(Number(total)).toLocaleString() }} FCFA</td>
            </tr>
          </tbody>
          <tbody v-else>
            <td colspan="2"
                class="text-center">Aucune donnée disponible...</td>
          </tbody>
        </table>
      </div>
      <div class="text-center mb-5"
           :class="width"
           style="height:300px;"
           v-if="yearData != null">
        <Pie :chartData="chartData"
             :height="270"></Pie>
      </div>
    </div>
    <!-- Fin ligne n°2 -->
    <!-- Début ligne n°3 -->
    <div class="row my-5">
      <div class="col-md-4 mx-auto mt-3">
        <div class="form-row row">
          <div class="form-group col-md-6"><label for="">Choisissez la gare</label></div>
          <div class="form-group col-md-6">
            <select class="form-control"
                    name=""
                    id=""
                    v-model="gare"
                    @change="changeGare()">
              <option value="ATTINGUE">ATTINGUE</option>
              <option value="SINGROBO">SINGROBO</option>
              <option value="THOMASSET">THOMASSET</option>
              <option value="MOAPE">MOAPE</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin ligne n°3 -->
    <!-- Début ligne n°4 -->
    <div class="row">
      <table class="table table-striped table-blue">
        <thead>
          <tr>
            <th class="text-center"></th>
            <th colspan="7"
                class="text-center tht-blue">{{ gare }}</th>
          </tr>
        </thead>
        <tbody v-if="gareData != null">
          <tr class="text-center">
            <td >Mois</td>
            <td>classe 1</td>
            <td>classe 2</td>
            <td>classe 3</td>
            <td>classe 4</td>
            <td>classe 5</td>
            <td class="tht-blue">SOUS-TOTAL</td>
          </tr>
  
          <tr v-for="(mois, mkey) in month"
              :key="mkey">
            <td >{{mois.mois}}</td>
            <td class="text-right">{{Math.round(Number(gareData.classe_1[mkey])).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(gareData.classe_2[mkey])).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(gareData.classe_3[mkey])).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(gareData.classe_4[mkey])).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(gareData.classe_5[mkey])).toLocaleString()}}</td>
            <td class="text-right tht-blue">{{Math.round(Number(mois.total)).toLocaleString()}}</td>
          </tr>
          <tr class="tr-blue">
            <td >TOTAUX</td>
            <td class="text-right">{{ Math.round(Number(gareData.totaux.classe_1)).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(Number(gareData.totaux.classe_2)).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(Number(gareData.totaux.classe_3)).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(Number(gareData.totaux.classe_4)).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(Number(gareData.totaux.classe_5)).toLocaleString() }}</td>
            <td class="tht-blue text-right">{{Math.round(Number(monthTotal)).toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Fin ligne n°4 -->
  </div>
</template>
<script>
// import Pie from "./PieChart"
import { mapActions,mapMutations, mapGetters } from "vuex"
import VueHtml2pdf from 'vue-html2pdf'
import BarChart from "@/components/shared/BarChart"
export default {
  name: "RctPeage",
  components: {
    // Pie,
    VueHtml2pdf,
    BarChart,
  },
  props:["extYear"],
  data: () => ({
    chartData: {
      labels: [],
      datasets: [
        {
          label: "Recette péage (%)",
          data: [],
          backgroundColor: [],
          hoverOffset: 4
        }
      ]
    },
    width:"col-md-5",
    year: new Date().getFullYear(),
    yearData: null,
    gare: "ATTINGUE",
    month:[
      {mois:"Janv.",total:0},
      {mois:"Fev.",total:0},
      {mois:"Mars",total:0},
      {mois:"Avr.",total:0},
      {mois:"Mai.",total:0},
      {mois:"Juin",total:0},
      {mois:"Juil.",total:0},
      {mois:"Août.",total:0},
      {mois:"Sept.",total:0},
      {mois:"Oct.",total:0},
      {mois:"Nov.",total:0},
      {mois:"Dec.",total:0},
    ],
    monthTotal:0,
    gareData: null,
    total:0,
    tableauCouleurs:["#0097A9", "#016677", "#00B5CB", "#00383F", "#01606B"]
  }),
  created() {
    this.year = this.extYear
    this.RecettePeage({ annee: this.year })
    this.RecettePeageG({ annee: this.year, gare:this.gare })
    if (window.innerWidth >1400) {
      this.width = "col-md-4 mx-auto"
    }
  },
  watch: {
    // arRecetteG() {
    //   this.yearData = this.arRecetteG
    //   this.addColor(this.yearData.recettes.length)
  
    //   if (this.yearData.recettes.length > 0){
    //     for (var index = 0; index < this.yearData.recettes.length; index++) {
    //       this.chartData.datasets[0].data.push(Math.round(Number(this.yearData.recettes[index].pourcentage) * 100) / 100)
    //       this.chartData.labels.push(this.yearData.recettes[index].gare + " (" + Math.round(Number(this.yearData.recettes[index].pourcentage) * 100) / 100 + "%)")
    //     }
    //   }else{
    //     this.chartData.labels=[]
    //     this.chartData.datasets[0].data=[]
    //     this.chartData.datasets[0].backgroundColor=[]
    //   }
    // },
    // arRecette() {
    //   this.gareData = this.arRecette
    //   for (var index = 1; index < 13; index++) {
    //     if (Object.prototype.hasOwnProperty.call(this.gareData.recettes, "0"+index)) {
    //       this.month.push(this.gareData.recettes['0'+index])
    //     }
    //   }
    // },
    recettePeageG(){
      if (this.recettePeageG["tableau"]) {
        if (this.recettePeageG["tableau"][0]) {
          this.gareData=this.recettePeageG["tableau"][0]
          this.monthTotal=0
          for (let index = 0; index < this.month.length; index++) {
            this.month[index].total= this.gareData.classe_1[index] + this.gareData.classe_2[index] + this.gareData.classe_3[index] + this.gareData.classe_4[index] + this.gareData.classe_5[index]
            this.monthTotal+=this.month[index].total
          }
          console.log(this.gareData)
        }else{
          this.gareData=this.recettePeageG["tableau"]
        }
      }
    },
    recettePeage(){
      if (this.recettePeage["tableau"]) {
        this.yearData = this.recettePeage
        this.total=0
        this.yearData.tableau.forEach(recette => {
          this.total+=Number(recette.recettes)
        })
        if (window.innerWidth >1400) {
          this.width = "col-md-4 mx-auto"
        }
        this.addColor(this.yearData.graphique.length)
          
        this.yearData.graphique.forEach(graphique => {
          this.chartData.datasets[0].data.push(Math.round(Number(graphique.pourcentage) * 100) / 100)
          this.chartData.labels.push(graphique.gare + " (" + Math.round(Number(graphique.pourcentage) * 100) / 100 + "%)")
        })
      }
    },
    extYear(){
      if (this.extYear) {
        this.setRecettePeage("")
        this.setRecettePeageG("")
        this.year = this.extYear
        this.yearData = null
        this.gareData = null
        this.RecettePeage({ annee: this.year })
        this.RecettePeageG({ annee: this.year, gare: this.gare })
          
        this.chartData.datasets[0].data = []
        this.chartData.labels = []
        this.chartData.datasets[0].backgroundColor=[]
      }
    }
  },
  computed: {
    ...mapGetters(["arRecetteG", "arRecette","recettePeage","recettePeageG"])
  },
  methods: {
    ...mapActions(["RecettePeage","RecettePeageG"]),
    ...mapMutations(["setRecettePeage","setRecettePeageG"]),
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    changeGare() {
      this.gareData = null
      // this.RecettePeage({ annee: this.year })
      this.RecettePeageG({ annee: this.year, gare: this.gare })
    },
    addColor(long){
      for (let i = 0; i < long; i++) {
        this.chartData.datasets[0].backgroundColor.push(this.tableauCouleurs[i])
      }
    }
  }
}
</script>
  